<template>
  <section id="add-product-shop">
    <!-- TITLE | GO BACK BUTTON -->
    <b-row class="mb-5">
      <b-col
        md="10"
        class=""
      >
        <h1>{{ $t('product.createProduct') }}</h1>
        <h5 class="text-primary">
          {{ $t('product.createAProduct') }}
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center">
        <b-img
          v-if="languageService"
          height="20px"
          width="30px"
          :src="languageFlags(defaultLanguage)"
          class="mr-2"
        />
        <b-button
          variant="danger"
          @click="goBack()"
        >
          {{ $t("dataGeneric.goBack") }}
        </b-button>
      </b-col>
    </b-row>
    <b-overlay
      variant="transparent"
      :show="show"
    >
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        :finish-button-text="$t('dataGeneric.save')"
        :back-button-text="$t('dataGeneric.goBack')"
        class="mb-3"
        @on-complete="formSubmitted"
      >
        <!-- PRODUCT INFO -->
        <tab-content
          :title="$t('product.info')"
          :before-change="validationFormInfo"
        >
          <validation-observer
            ref="infoRules"
            tag="form"
          >
            <!-- PRODUCT NAME -->
            <b-row class="form-group">
              <b-col>
                <b-form-group :label="$t('dataGeneric.name') + ' *'">
                  <validation-provider
                    v-slot="validationContext"
                    name="name"
                    :rules="{
                      required: true,
                      min: 3,
                      regex: /^[a-zA-Z0-9\s.,'!?()-áéíóúñ%#€&ç]+$/
                    }"
                  >
                    <b-form-input
                      v-model="productName"
                      :state="validationContext.errors.length > 0 ? false : null"
                      :placeholder="$t('product.name')"
                      maxlength="150"
                    />
                    <small class="text-danger">{{ fix(validationContext) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- PRODUCT SHORT DESCRIPTION -->
            <b-row class="form-group">
              <b-col>
                <b-form-group :label="$t('category.shortDescription')+' *'">
                  <validation-provider
                    v-slot="validationContext"
                    name="ShortDescription"
                    :rules="{
                      required: true,
                      regex: /^[a-zA-Z0-9\s.,'!?()-áéíóúñ%#€&ç]+$/
                    }"
                  >
                    <b-form-input
                      v-model="productShortDescription"
                      required
                      :state="validationContext.errors.length > 0 ? false : null"
                      :placeholder="$t('product.shortDescription')"
                      maxlength="180"
                    />
                    <small class="text-danger">{{ fix(validationContext) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- PRODUCT DESCRIPTION -->
            <b-row class="form-group">
              <b-col>
                <b-form-group :label="$t('dataGeneric.description')">
                  <validation-provider
                    v-slot="{ errors }"
                    name="description"
                    :rules="{
                      regex: /^[a-zA-Z0-9\s.,'!?()-áéíóúñ%#€&ç]+$/
                    }"
                  >
                    <b-form-textarea
                      v-model="productDescription"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('product.description')"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="form-group">
              <!-- SELECT STATE -->
              <b-col cols="2">
                <b-form-group :label="$t('message.tableHeader.status')">
                  <b-form-select
                    v-model="contentState"
                    :label="$t('message.tableHeader.status')"
                  >
                    <b-form-select-option
                      v-for="s in state"
                      :key="s.value"
                      :value="s.value"
                    >
                      {{ s.text }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <!-- EXTERNAL REFERENCE -->
              <b-col cols="5">
                <b-form-group :label="$t('contents.reference')">
                  <b-form-input
                    v-model="reference"
                    maxlength="150"
                    value="reference"
                    :placeholder="$t('externalRefProduct')"
                  />
                </b-form-group>
              </b-col>
              <!-- PRICE -->
              <b-col cols="5">
                <b-form-group :label="$t('salePrice') + '  ( ' + currencyType[currency] + ' )'">
                  <validation-provider
                    v-slot="{ errors }"
                    name="price"
                    :rules="{
                      regex: /^[0-9]+(?:\.[0-9]{1,2})?$/
                    }"
                  >
                    <b-form-input
                      v-model="price"
                      :state="errors.length > 0 ? false : null"
                      type="number"
                      step="0.01"
                      :placeholder="$t('salePrice')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group :label="$t('priceOld') + '  ( ' + currencyType[currency] + ' )'">
                  <validation-provider
                    v-slot="{ errors }"
                    name="priceOld"
                    :rules="{
                      regex: /^[0-9]+(?:\.[0-9]{1,2})?$/
                    }"
                  >
                    <b-form-input
                      v-model="priceOld"
                      :state="errors.length > 0 ? false : null"
                      type="number"
                      step="0.01"
                      :placeholder="$t('priceOld')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group :label="$t('stock')">
                  <validation-provider
                    v-slot="{ errors }"
                    name="stock"
                    :rules="{
                      regex: /^[0-9]+(?:\.[0-9]{1,2})?$/
                    }"
                  >
                    <b-form-input
                      v-model="stock"
                      :state="errors.length > 0 ? false : null"
                      type="number"
                      :placeholder="$t('stock')"
                      class="mr-1"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- PRODUCT DIMENSIONS -->
            <b-row class="form-group">
              <b-col cols="3">
                <b-form-group :label="$t('weight')">
                  <validation-provider
                    v-slot="{ errors }"
                    name="weight"
                    :rules="{
                      regex: /^[0-9]+(?:\.[0-9]{1,2})?$/
                    }"
                  >
                    <b-form-input
                      v-model="weight"
                      :state="errors.length > 0 ? false : null"
                      type="number"
                      :placeholder="$t('weight')"
                      step="0.01"
                      class="mr-1"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group :label="$t('ancho')">
                  <validation-provider
                    v-slot="{ errors }"
                    name="width"
                    :rules="{
                      regex: /^[0-9]+(?:\.[0-9]{1,2})?$/
                    }"
                  >
                    <b-form-input
                      v-model="size.width"
                      :state="errors.length > 0 ? false : null"
                      type="number"
                      :placeholder="$t('ancho')"
                      step="0.01"
                      class="mr-1"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group :label="$t('alto')">
                  <validation-provider
                    v-slot="{ errors }"
                    name="high"
                    :rules="{
                      regex: /^[0-9]+(?:\.[0-9]{1,2})?$/
                    }"
                  >
                    <b-form-input
                      v-model="size.high"
                      :state="errors.length > 0 ? false : null"
                      type="number"
                      :placeholder="$t('alto')"
                      step="0.01"
                      class="mr-1"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group :label="$t('largo')">
                  <validation-provider
                    v-slot="{ errors }"
                    name="long"
                    :rules="{
                      regex: /^[0-9]+(?:\.[0-9]{1,2})?$/
                    }"
                  >
                    <b-form-input
                      v-model="size.long"
                      :state="errors.length > 0 ? false : null"
                      type="number"
                      :placeholder="$t('largo')"
                      step="0.01"
                      class="mr-1"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- TAGS -->
            <b-row class="form-group">
              <b-col class="d-flex justify-content-between align-items-center">
                <b-form-group
                  class="mb-2"
                  style="flex-grow: 1;"
                >
                  <div class="d-flex align-items-center">
                    <label class="bv-no-focus-ring col-form-label pt-0">
                      {{ $t('productTags') }}
                    </label>
                    <feather-icon
                      v-b-tooltip.hover.top.v-primary="$t('addTags')"
                      class="ml-1 info-icon"
                      icon="InfoIcon"
                      size="20"
                    />
                  </div>
                  <v-select
                    v-model="selectedTags"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    taggable
                    push-tags
                    :placeholder="$t('addTagsC')"
                    @input="modalTags()"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!-- VENDOR -->
            <b-row class="form-group">
              <b-col class="d-flex justify-content-end align-items-center">
                <b-form-group
                  :label="$t('VendProd')"
                  style="flex-grow: 1;"
                >
                  <div @click="$refs['vendor-modal'].show()">
                    <b-form-input
                      id="category"
                      v-model="vendorName"
                      autocomplete="new-password"
                      :placeholder="$t('VendProd')"
                      disabled
                    />
                  </div>
                </b-form-group>
                <b-button
                  variant="info"
                  size="md"
                  class="ml-1 mr-1 text-nowrap vendor-margin"
                  style="height: fit-content;"
                  @click="$refs['vendor-modal'].show()"
                >
                  {{ $t('Select') }}
                </b-button>
                <b-form-checkbox
                  v-model="isSpecial"
                  class="vendor-margin"
                  switch
                  disabled
                >
                  {{ $t('isSpecialProduct') }}
                </b-form-checkbox>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <!-- PRODUCT IMAGES -->
        <tab-content :title="$t('product.images')">
          <b-row class="form-group">
            <b-col>
              <h5 class="mb-0">
                {{ $t('Images') }}
              </h5>
              <small class="text-muted">
                {{ $t("product.image") }}
              </small>
            </b-col>
          </b-row>
          <b-row class="form-group">
            <b-col>
              <b-form-group
                class="mb-2 mr-1"
                :label="$t('resources.originMedia')"
              >
                <b-form-select
                  v-model="mediaLocation"
                  class="mr-1"
                  :options="mediaOriginOptions"
                />
              </b-form-group>
              <div>
                <!-- TV | WEB -->
                <b-form-group class="m-1">
                  <label>
                    <strong>
                      TV/Web
                    </strong>
                  </label>
                  <b-row class="d-flex justify-content-around">
                    <!-- CARD -->
                    <image-element-vue
                      :image-origin="mediaLocation == 'BUK' ? imageCategory : imageCategoryUrl"
                      :media-origin-selected="mediaLocation"
                      :image-type="'imageCategory'"
                      :label="$t('contents.imgInternal')"
                      @saveImage="loadImage"
                    />
                    <!-- BACKGROUND -->
                    <image-element-vue
                      :image-origin="mediaLocation == 'BUK' ? backgroundCategory : backgroundCategoryURL"
                      :media-origin-selected="mediaLocation"
                      :image-type="'backgroundCategory'"
                      :label="$t('contents.bckgInternal') + ' ' + $t('precontent')"
                      @saveImage="loadImage"
                    />
                    <b-col cols="3" />
                  </b-row>
                </b-form-group>
                <!-- SMARTPHONES -->
                <b-form-group class="m-1">
                  <label>
                    <strong>
                      {{ $t('Movil') }}
                    </strong>
                  </label>
                  <b-row class="d-flex justify-content-around">
                    <!-- CARD -->
                    <image-element-vue
                      :image-origin="mediaLocation == 'BUK' ? mobileImageCategory : mobileImageCategoryURL"
                      :media-origin-selected="mediaLocation"
                      :image-type="'mobileImageCategory'"
                      :label="$t('contents.mobileImgInternal')"
                      @saveImage="loadImage"
                    />
                    <b-col cols="7" />
                  </b-row>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </tab-content>
        <!-- SEO -->
        <tab-content
          title="SEO"
          :before-change="validationFormSeo"
        >
          <validation-observer
            ref="seoRules"
            tag="form"
          >
            <!-- SEO TITLE -->
            <b-row class="form-group">
              <b-col>
                <b-form-group :label="$t('title')">
                  <validation-provider
                    v-slot="{ errors }"
                    name="SEO title"
                    :rules="{
                      regex: /^[a-zA-Z0-9\s.,'!?()-áéíóúñ]*$/
                    }"
                  >
                    <b-form-input
                      v-model="seoTitle"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('title')"
                      maxlength="80"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- SEO DESCRIPTION -->
            <b-row class="form-group">
              <b-col>
                <b-form-group :label="$t('dataGeneric.description')">
                  <validation-provider
                    v-slot="{ errors }"
                    name="SEO description"
                    :rules="{
                      regex: /^[a-zA-Z0-9\s.,'!?()-áéíóúñ]*$/
                    }"
                  >
                    <b-form-textarea
                      v-model="seoDescription"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('dataGeneric.description')"
                      maxlength="180"
                      no-resize
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- FRIENDLY URL -->
            <b-row class="form-group">
              <b-col>
                <b-form-group :label="$t('friendUrl')">
                  <validation-provider
                    v-slot="{ errors }"
                    name="friendly url"
                    :rules="{
                      regex: /^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/
                    }"
                  >
                    <b-form-input
                      v-model="friendlyUrl"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('friendUrl')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- FRIENDLY URL RESULT -->
            <b-row
              v-if="friendlyUrlValue"
              class="form-group"
            >
              <b-col>
                <b-form-group
                  class="mb-2 mr-1"
                  :label="$t('converFriendUrl') + ':'"
                >
                  {{ friendlyUrlValue }}
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </b-overlay>
    <!-- SELECT VENDOR MODAL -->
    <b-modal
      ref="vendor-modal"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
    >
      <div class="d-block text-center">
        <vendor-selector @vendor="selectSeller" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal('vendor-modal')"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { getUserData } from '@/auth/utils'
import vSelect from 'vue-select'
import axios from '@axios'
import { showToast, messageError } from '@/store/functions'
import ImageElementVue from '@/views/components/ImageElement.vue'
import {
  BButton,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BModal,
  BOverlay,
  BRow,
  VBTooltip,
} from 'bootstrap-vue'
import languageFlags from '@/@core/utils/languageFlags'
import VendorSelector from '../../common/VendorSelectorModal.vue'

export default {
  components: {
    BButton,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BImg,
    BModal,
    BOverlay,
    BRow,
    FormWizard,
    ImageElementVue,
    TabContent,
    ValidationObserver,
    ValidationProvider,
    VendorSelector,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      backgroundCategory: null,
      languageFlags,
      backgroundCategoryURL: null,
      categoryId: this.$route.params.id != null ? this.$route.params.id : null,
      contentState: 'GRE',
      show: false,
      currency: null,
      currencyType: {
        EUR: '€',
        USD: '$',
        GBP: '£',
        ARS: 'Argentine peso',
      },
      externalReference: '',
      friendlyUrl: null,
      friendlyUrlValue: null,
      idTags: [],
      imageCategory: null,
      imageCategoryUrl: null,
      isSpecial: false,
      mediaLocation: 'BUK',
      mediaOriginOptions: [
        { value: 'BUK', text: this.$t('dataGeneric.internal') },
        { value: 'DIR', text: this.$t('dataGeneric.external') },
      ],
      mobileImageCategory: null,
      mobileImageCategoryURL: null,
      originalTags: [],
      price: 0,
      priceOld: 0,
      stock: 0,
      productName: '',
      productDescription: '',
      productShortDescription: '',
      reference: '',
      required,
      selectedTags: [],
      vendorId: null,
      defaultLanguage: '',
      languageService: false,
      vendorName: null,
      seoTitle: '',
      seoDescription: '',
      size: {
        width: 0,
        high: 0,
        long: 0,
      },
      state: [
        { value: 'RED', text: `🔴 ${this.$t('code.desactivado')}` },
        { value: 'YEW', text: `🟡 ${this.$t('dataGeneric.internal')}` },
        { value: 'GRE', text: `🟢 ${this.$t('ecommerce.public')}` },
      ],
      userData: getUserData(),
      weight: 0,
    }
  },
  watch: {
    friendlyUrl() {
      this.friendlyUrlValue = this.friendlyUrl
        .replace(/[`~!@#$%^&*()_\-+=[\]{};:'"\\|/,.<>?\s]/g, ' ')
        .toLowerCase()

      this.friendlyUrlValue = this.friendlyUrlValue.replace(/^\s+|\s+$/gm, '')
      this.friendlyUrlValue = this.friendlyUrlValue.replace(/\s+/g, '-')
    },
  },
  mounted() {
    this.getDefaultLanguage()
    this.getTags()
    this.getPayMethod()
  },
  methods: {
    fix(data) {
      let message = ''
      const keyExists = Object.keys(data.failedRules)
      if (keyExists.includes('required')) {
        message = this.$t('required')
      }
      if (keyExists.includes('min')) {
        message = this.$t('purchases.nameFormThreeCaract')
      }
      if (keyExists.includes('regex')) {
        message = this.$t('purchases.specialCharacters')
      }

      return message
    },
    getDefaultLanguage() {
      axios
        .post('', {
          query: `
             query{
             client(id:"${this.userData.profile.client.id}"){
                 defaultLanguage
                 availableLanguages
             }
         }
         `,
        })
        .then(res => {
          const { availableLanguages } = res.data.data.client

          this.defaultLanguage = res.data.data.client.defaultLanguage
          const arr = availableLanguages.split('|')
          this.languageService = arr.length > 1
        })
        .catch(() => {
        })
    },
    formSubmitted() {
      const headers = {
        'Accept-Language': this.defaultLanguage,
        'Content-Language': this.defaultLanguage,
      }
      this.show = true
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const query = `
        mutation (
          $name: String!,
          $client: ID!,
          $imageUrl: String,
          $backgroundUrl: String,
          $imageMobileUrl: String
      ${this.reference ? ', $reference: String' : ''}
      ${this.productShortDescription ? ', $shortDescription: String' : ''}
      ${this.productDescription ? ', $description: String' : ''}
      ${this.contentState ? ', $state: ProductStateInput' : ''}
      ${this.price ? ', $price: Float' : ''}
      ${this.priceOld ? ', $priceOld: Float' : ''}
      ${this.stock ? ', $stock: Int' : ''}
      ${this.weight ? ', $weight: Float' : ''}
      ${this.size.width ? ', $width: Float' : ''}
      ${this.size.high ? ', $high: Float' : ''}
      ${this.size.long ? ', $long: Float' : ''}
      ${this.idTags ? ', $tags: [ID]' : ''}
      ${this.vendorId ? ', $vendor: ID' : ''}
      ${this.seoTitle ? ', $seoTitle: String' : ''}
      ${this.seoDescription ? ', $seoDescription: String' : ''}
      ${this.friendlyUrl ? ', $friendlyUrl: String' : ''}
        ) {
          createProductsShop(input:{
            name: $name,
            client: $client,
            imageUrl: $imageUrl,
            backgroundUrl: $backgroundUrl,
            imageMobileUrl: $imageMobileUrl,
            categories: ["${this.categoryId}"]
      ${this.reference ? ', reference: $reference' : ''}
      ${this.productShortDescription ? ', shortDescription: $shortDescription' : ''}
      ${this.productDescription ? ', description: $description' : ''}
      ${this.contentState ? ', state: $state' : ''}
      ${this.price ? ', price: $price' : ''}
      ${this.priceOld ? ', priceOld: $priceOld' : ''}
      ${this.stock ? ', stock: $stock' : ''}
      ${this.weight ? ', weight: $weight' : ''}
      ${this.size.width ? ', width: $width' : ''}
      ${this.size.high ? ', high: $high' : ''}
      ${this.size.long ? ', long: $long' : ''}
      ${this.idTags ? ', tags: $tags' : ''}
      ${this.vendorId ? ', vendor: $vendor' : ''}
      ${this.seoTitle ? ', seoTitle: $seoTitle' : ''}
      ${this.seoDescription ? ', seoDescription: $seoDescription' : ''}
      ${this.friendlyUrl ? ', friendlyUrl: $friendlyUrl' : ''}
          }) {
            product {
              id,
              name
            }
          }
        }
      `
      const variables = {
        name: this.productName,
        reference: this.reference,
        client: this.userData.profile.client.id,
        shortDescription: this.productShortDescription,
        description: this.productDescription,
        state: this.contentState,
        price: this.price,
        priceOld: this.priceOld,
        stock: this.stock,
        weight: this.weight,
        width: this.size.width,
        high: this.size.high,
        long: this.size.long,
        tags: [...this.idTags],
        vendor: this.vendorId,
        seoTitle: this.seoTitle,
        seoDescription: this.seoDescription,
        friendlyUrl: this.friendlyUrl,
        imageUrl: this.imageCategoryUrl,
        backgroundUrl: this.backgroundCategoryURL,
        imageMobileUrl: this.mobileImageCategoryURL,
      }
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))
      data.append('image', this.imageCategory)
      data.append('background', this.backgroundCategory)
      data.append('imageMobile', this.mobileImageCategory)

      axios
        .post('', data, { headers }, config)
        .then(result => {
          messageError(result, this)
          showToast(this.$t('product.add-success'), 1, this)

          this.show = false
          if (result.status === 200 && result.data.data.createProductsShop.product.id) {
            this.goBack()
          }
        })
        .catch(err => {
          this.show = false

          showToast(this.$t('product.add-error'), 2, this)

          // eslint-disable-next-line no-console
          console.error(err)
        })
    },
    goBack() {
      window.history.back()
    },
    hideModal(modalName) {
      this.$refs[modalName].hide()
    },
    loadImage(item) {
      const { data, type } = item
      switch (type) {
        case 'imageCategory':
          if (this.mediaLocation === 'BUK') { this.imageCategory = data } else { this.imageCategoryUrl = data }
          break
        case 'backgroundCategory':
          if (this.mediaLocation === 'BUK') { this.backgroundCategory = data } else { this.backgroundCategoryURL = data }
          break
        case 'mobileImageCategory':
          if (this.mediaLocation === 'BUK') { this.mobileImageCategory = data } else { this.mobileImageCategoryURL = data }
          break
        default:
          break
      }
    },
    modalTags() {
      if (this.selectedTags.length > this.idTags.length) {
        let id = ''
        axios
          .post('', {
            variables: {
              name: this.selectedTags[this.selectedTags.length - 1],
            },
            query: `
              query ($name: String) {
                allTags(name: $name) {
                  edges {
                    node {
                      id
                      name
                      createdAt
                    }
                  }
                }
              }
            `,
          })
          .then(result => {
            messageError(result, this)

            let create = true
            result.data.data.allTags.edges.forEach(element => {
              if (element.node.name.toLowerCase() === this.selectedTags[this.selectedTags.length - 1].toLowerCase()) {
                id = element.node.id
                create = false
              }
            })

            if (create) {
              axios
                .post('', {
                  variables: {
                    name: this.selectedTags[this.selectedTags.length - 1],
                  },
                  query: `
                    mutation ($name: String!) {
                      createTag(input:{name: $name}) {
                        tag {
                          id
                          name
                        }
                      }
                    }
                  `,
                })
                .then(res => {
                  messageError(res, this)

                  this.idTags.push(res.data.data.createTag.tag.id)
                })
                .catch(err => {
                  // eslint-disable-next-line
                  console.log(err)
                })
            } else {
              this.idTags.push(id)
            }
          })
          .catch(err => {
            // eslint-disable-next-line
            console.log(err)
          })
      } else {
        this.originalTags.forEach((a, index) => {
          let remove = true
          this.selectedTags.forEach(element => {
            if (element.toLowerCase() === a.toLowerCase()) {
              remove = false
            }
          })
          if (remove) {
            this.idTags.splice(index, 1)
            this.originalTags.splice(index, 1)
          }
        })
      }
    },
    getTags() {
      axios
        .post('', {
          query: `
            {
              allTags {
                edges {
                  node {
                    id
                    name
                    createdAt
                  }
                }
              }
            }
          `,
        })
        .then(result => {
          messageError(result, this)

          result.data.data.allTags.edges.forEach(element => {
            this.option.push({
              title: element.node.name,
              value: element.node.id,
            })
          })
        })
        .catch(() => { })
    },
    getPayMethod() {
      axios
        .post('', {
          query: `
            query{
              client(id:"${this.userData.profile.client.id}"){
                externalPayMethod
                currency
              }
          }
      `,
        })
        .then(res => {
          messageError(res, this)
          // this.externalPayMethod = res.data.data.client.externalPayMethod;
          this.currency = res.data.data.client.currency
        })
        .catch(res => {
          // eslint-disable-next-line no-console
          console.log(res)
          // this.externalPayMethod = null;
        })
    },
    selectSeller(data) {
      this.hideModal('vendor-modal')
      this.vendorId = data.id
      this.vendorName = data.vendorName
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSeo() {
      return new Promise((resolve, reject) => {
        this.$refs.seoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";

#add-product-shop .base-card-input {
  display: block;
  /*  width: 200px;
  height: 200px; */
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

#add-product-shop .placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#add-product-shop .placeholder:hover {
  background: #e0e0e0;
}

#add-product-shop .file-input {
  display: none;
}

.info-icon {
  padding-bottom: calc(0.438rem + 1px);
}

.vendor-margin {
  margin-top: 1rem;
}
</style>
